import {keyframes} from '@emotion/react'

export const MINI_LOGO_BREAKPOINT = 1500

export const searchWrapperExtensionAnimation = keyframes`
    0% {
        height: 0px;
    }
    100% {
        height: 95px;
    }
`

export const searchWrapperCollapseAnimation = keyframes`
    0% {
        height: 95px;
    }
    100% {
        height: 0px;
    }
`

export const searchTriggerExtension = keyframes`
    0% {
        transform: translateY(0) scale(0.75);
    }
    25% {
        transform: translateY(30px) scale(0.77);
    }
    50% {
        transform: translateY(60px) scale(0.79);
    }
    99% {
        opacity: 1;
    }
    100% {
        transform: translateY(78px) scale(1);
    }
`

export const searchTriggerCollapse = keyframes`
    0% {
        transform: translateY(78px) scale(1);
        visibility: hidden;
        opacity: 0;
    }
    1% {
        visibility: visible;
        opacity: 1;
    }
    50% {
        transform: translateY(60px) scale(0.79);
    }
    75% {
        transform: translateY(30px) scale(0.77);
    }
    100% {
        zoom: 75%;
    }
`

export const searchLinksSlideBottom = keyframes`
    0% {
        transform: translateY(-50px) scale(0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: translateY(-50px) scale(0.1);
    }
    100% {
        transform: translateY(30px) scale(1);
    }
`

export const searchLinksSlideTop = keyframes`
    0% {
        transform: translateY(30px) scale(1);
        opacity: 1;
    }
    50% {
        opacity: 1;
        transform: translateY(-50px) scale(0.1);
    }
    100% {
        transform: translateY(-50px) scale(0.1);
        opacity: 0;
    }
`
