import type {GlobalPageProperties} from '@slices/global'
import {GlobalPageContent} from '@slices/global'
import {DynamicSeoProvider} from '@providers/dynamic-seo'
import {HeaderWithSearch} from '@/library/components/features/header'

export default function GlobalPage({hydrationData}: GlobalPageProperties) {
  return (
    <DynamicSeoProvider content={hydrationData?.info?.content}>
      <HeaderWithSearch />
      <GlobalPageContent />
    </DynamicSeoProvider>
  )
}

export {getGlobalPageProperties as getServerSideProps} from '@slices/global'
