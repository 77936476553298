import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {selectors} from '@components/features/search-input/store'
import {useState} from 'react'

import {ANIMATION_STYLES} from '@/library/constants/css'
import {css} from '@emotion/react'
import {useIsMounted} from '@hooks/use-is-mounted'
import {Link} from '@/library/components/ui'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import useTranslation from 'next-translate/useTranslation'
import {
  searchLinksSlideBottom,
  searchLinksSlideTop,
  searchTriggerCollapse,
  searchTriggerExtension,
} from '../../lib/constants'
import {SearchInput} from '../../../search-input'

const Wrapper = styled.div<{$isSearchOpen: boolean; $mounted: boolean}>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: ${({$isSearchOpen}) =>
      $isSearchOpen ? searchTriggerExtension : searchTriggerCollapse}
    ${ANIMATION_STYLES};
  animation-fill-mode: both;

  ${({$mounted}) =>
    !$mounted &&
    css`
      animation-duration: 0s !important;
      -webkit-animation-duration: 0s !important;
    `}
`

const SearchLinksWrapper = styled.div<{
  $isSearchOpen: boolean
  $mounted: boolean
}>`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  gap: 20px;

  -webkit-animation: ${({$isSearchOpen}) =>
      $isSearchOpen ? searchLinksSlideBottom : searchLinksSlideTop}
    ${ANIMATION_STYLES};
  animation-fill-mode: both;

  ${({$mounted}) =>
    !$mounted &&
    css`
      animation-duration: 0s !important;
      -webkit-animation-duration: 0s !important;
    `}
`

export const SearchTrigger = observer(() => {
  const {t} = useTranslation('features.header')
  const [mounted, setMounted] = useState(false)
  const isMountedInClient = useIsMounted()
  const {isOpen} = useLocalObservable(selectors.selectHeaderSearchIsOpen)

  const handleClickSearch = () => {
    setMounted(true)
  }

  if (!isMountedInClient) {
    return null
  }

  return (
    <>
      <Wrapper
        $isSearchOpen={isOpen}
        onClick={handleClickSearch}
        $mounted={mounted}
        onMouseOver={() => setMounted(true)}
        onFocus={handleClickSearch}
      >
        <SearchInput />
      </Wrapper>
      <SearchLinksWrapper
        $isSearchOpen={isOpen}
        $mounted={mounted}
        onMouseOver={() => setMounted(true)}
      >
        <Link href={ENVIRONMENT.HOW_TO_BOOK_ARTICLE} rel="nofollow">
          {t('links.knowledge_base')}
        </Link>
        <Link href={ENVIRONMENT.INFO} rel="nofollow">
          {t('links.travel_ideas')}
        </Link>
      </SearchLinksWrapper>
    </>
  )
})
