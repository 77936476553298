import styled from '@emotion/styled'
import Link from 'next/link'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {Routes} from '@/types/enums/routes'
import {css} from '@emotion/react'
import {
  ANIMATION_STYLES,
  LOADING_STYLES,
  TRANSITION_STYLES,
} from '@/library/constants/css'
import {useLocalObservable} from 'mobx-react-lite'

import {observer} from 'mobx-react'
import {Illustration} from '@ui/illustration'
import {store} from '@store/index'
import {useState} from 'react'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {HeaderActions} from './ui/actions'
import {selectors} from './store'
import {SearchTrigger} from './ui/search-trigger'
import {SearchInput} from '../search-input'
import {
  MINI_LOGO_BREAKPOINT,
  searchWrapperCollapseAnimation,
  searchWrapperExtensionAnimation,
} from './lib/constants'
import {Button} from '../../ui'
import {Icon} from '../../ui/icon'

const StyledHeader = styled.header<{
  $isLoading: boolean
  $isSearchOpen: boolean
}>`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  transition: height ${TRANSITION_STYLES};

  ${({$isLoading}) =>
    $isLoading &&
    css`
      ${LOADING_STYLES}
    `}

  ${({$isSearchOpen}) =>
    $isSearchOpen &&
    css`
      z-index: 30;
    `}
`

const Wrapper = styled.div<{$isSearchOpen: boolean; $mounted: boolean}>`
  position: relative;
  width: 100%;
  height: 80px;

  &::before {
    box-sizing: border-box;
    content: '';
    position: absolute;
    left: 0;
    top: 80px;
    height: 0px;
    width: 100%;
    background-color: ${({theme}) => theme.palette.backgroundQuaternary};
    -webkit-animation: ${({$isSearchOpen}) =>
        $isSearchOpen
          ? searchWrapperExtensionAnimation
          : searchWrapperCollapseAnimation}
      ${ANIMATION_STYLES};
    animation-fill-mode: both;

    ${({$mounted}) =>
      !$mounted &&
      css`
        animation-duration: 0s !important;
        -webkit-animation-duration: 0s !important;
      `}
  }
`

const Container = styled.div<{$isTouch?: boolean; maxWidth?: number}>`
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 100%;
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const SearchTriggerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  align-items: end;
  margin-left: 100px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    margin-left: 0;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.palette.overlayPrimary};
  opacity: 0.25;
  z-index: 19;
`

const SearchButton = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;

  svg {
    color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  }
`

export const HeaderWithSearch = observer(() => {
  const isTouch = useDeviceDetection('touch')
  const [mounted, setMounted] = useState(false)
  const {isLoading} = useLocalObservable(selectors.selectHeaderIsLoading)
  const {isOpen} = useLocalObservable(selectors.selectHeaderSearchIsOpen)
  const isDesktopSearchOpen = !isTouch && isOpen
  const windowSize = useWindowSize()
  const miniLogo = windowSize <= MINI_LOGO_BREAKPOINT && !isTouch

  const handleClickSearch = () => {
    store().search.setIsOpenMobileMainPage(true)
  }

  return (
    <>
      <StyledHeader $isLoading={isLoading} $isSearchOpen={isDesktopSearchOpen}>
        <Wrapper
          $isSearchOpen={isDesktopSearchOpen}
          $mounted={mounted}
          onMouseOver={() => setMounted(true)}
          onFocus={() => setMounted(true)}
        >
          <Container $isTouch={isTouch}>
            <StyledLink href={Routes.HOME}>
              <Illustration
                name={miniLogo ? 'logo_small' : 'logo_large'}
                width={miniLogo ? 50 : 190}
                height="100%"
              />
            </StyledLink>
            {isTouch ? (
              <>
                <SearchInput className="visually-hidden" />
                <SearchButton variant="text" onClick={handleClickSearch}>
                  <Icon name="search" width={24} height={24} />
                </SearchButton>
              </>
            ) : (
              <>
                <SearchTriggerWrapper>
                  <SearchTrigger />
                </SearchTriggerWrapper>
                <HeaderActions />
              </>
            )}
          </Container>
        </Wrapper>
      </StyledHeader>
      {isDesktopSearchOpen && (
        <Overlay onClick={() => store().search.resetActiveInput()} />
      )}
    </>
  )
})
